import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BackToTop from "../../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const ToddRiecks = () => {
  return (
    <Layout>
      <Seo title="Doctor Todd Riecks, DVM" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero no-videoHero">
              <h1>
                Meet
                <br />
                Dr. Todd
                <br />
                Riecks
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="about-wrapper py-5"> */}
      <Container className="acl-container mb-5 mt-5 mt-md-0">
        <div className="doctor-body">
          <Row className="border-none">
            <Col md={4} className="img-resp">
              <LazyLoadImage
                effect="blur"
                src="/assets/img/dr-todd-riecks.jpg"
                alt="Doctor"
                className="img-fluid mb-4"
              />
            </Col>
            <Col md={8}>
              <p>
                Dr. Riecks who was born and raised in Ohio, received his DVM
                from The Ohio State University. He went on to complete his
                internship and residency at Med Vet Associates, Ltd. in
                Columbus, Ohio, a nationally recognized advanced muti-specialty,
                emergency, and cancer center.
              </p>
              <p>
                His passion for canine orthopedic and neurosurgery allows him
                the privilege to "...care for and interact with so many
                wonderful pets and their people. It is awesome to witness
                surgical success and see these dogs and cats return to their
                fun, active CO lifestyles! Positive owner accounts and
                compliments continue to humble my practice." He’s thrilled to be
                living in Colorful Colorado where he enjoys snowboarding, Alpine
                Touring, camping, backpacking, and photography. Todd is a
                registered yoga teacher and enjoys a committed daily yoga
                practice of his own. He is a dedicated participant in the Tiny
                House Movement and strives for a minimal earth impact.
              </p>
              <p>
                He is delighted with his daughters, Jesse and Jaime. He shares
                his tiny space with his partner Heather and a Border Terrier,
                'Manitou.'
              </p>
            </Col>
          </Row>
        </div>
        <div className="doctor-video">
          <Row className="border-none">
            <Col md={12}>
              <div className="embed-container">
                <iframe
                  src="https://player.vimeo.com/video/445627694"
                  frameborder="0"
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen
                  title="doctor"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <BackToTop />
      {/* </div> */}
    </Layout>
  )
}

export default ToddRiecks
